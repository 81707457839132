<template>
  <div>
    <loader :loading="loading" :backdrop="true"/>
    <div class="tiles">
      <div
        class="tile"
        v-for="partner in partners"
        :key="partner.partner_uuid"
        @click="handleTileClicked(partner.uuid)"
      >
        <div class="logo">P</div>
        <div>
          <div class="name">{{partner.name}}</div>
          <div class="categories">{{getPartnerCategories(partner)}}</div>
        </div>
      </div>
      <div class="tile add-button" @click="addIntegration">
        <icon name="plus" class="w-6 mr-2 inline"></icon>
        add new
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from "@/components/ui/Icon";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Loader from "@/components/ui/Loader";

  export default {
    name: "IntegrationsList",
    components: {Icon, Loader},
    mixins: [ModalNavigation, NotifyMixin],
    data() {
      return {
        partners: [],
        loading: false,
      }
    },
    methods: {
      addIntegration() {
        this.$router.replace({name: 'integrations.create'});
      },
      fetchPartners() {
        this.loading = true;
        this.$partnerDataProvider.getList('partners')
          .then(({content}) => {
            this.partners = content;
          })
          .catch((error) => this.notifyError(error.message))
          .finally(() => this.loading = false);
      },
      handleTileClicked(partnerId) {
        this.$router.replace({
          name: this.$route.params?.passFlowTo ? this.$route.params.passFlowTo :`integrations.edit`,
          params: {partnerId}
        });
      },
      getPartnerCategories(partner) {
        return partner.purposes
          .map(purpose => purpose.category?.name)
          .filter((value, index, self) => self.indexOf(value) === index)
          .join(', ');
      }
    },
    mounted() {
      this.fetchPartners();
    }
  }
</script>
